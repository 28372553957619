// WeddingTimeline.jsx
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Heart, Flame, Anchor, Star, Play, Lock, ImageOff } from 'lucide-react';
import { useTheme } from '../context/ThemeContext';
import CountdownTimer from './CountdownTimer';
import TimelineImage from './TimelineImage';
import Footer from './Footer';
import SectionScroll from './SectionScroll';
import AnimeEffects from './AnimeEffects';
import VideoPlayer from './VideoPlayer';

// Keeping existing timeline data
const timelineData = [
  { days: 14, image: 'https://static.dhrumilpatel.in/images/RSwy9k2m.jpg', caption: 'The Journey Begins', message: 'Coming Soon!' },
  { days: 10, image: 'https://static.dhrumilpatel.in/images/RSp4n8vx.jpg', caption: '10 Days to Our Wedding!', message: 'The excitement builds as we prepare for our celebration!' },
  { days: 9, image: 'https://static.dhrumilpatel.in/images/RSj7h5zt.jpg', caption: '9 Days Until Our Special Day!', message: 'Soon we\'ll celebrate with Garba and begin our journey together!' },
  { days: 8, image: 'https://static.dhrumilpatel.in/images/RSq2c6lk.jpg', caption: '8 Days to Go!', message: 'The preparations for our grand celebration are in full swing!' },
  { days: 7, image: 'https://static.dhrumilpatel.in/images/RSb8m3fn.jpg', caption: '1 Week to Our Wedding!', message: 'In seven days, we\'ll begin our beautiful journey with the blessings of our families!' },
  { days: 6, image: 'https://static.dhrumilpatel.in/images/RSt5x9dv.jpg', caption: '6 Days Until We Unite!', message: 'The countdown to our wedding ceremonies begins!' },
  { days: 5, image: 'https://static.dhrumilpatel.in/images/RSr7k4ah.jpg', caption: '5 Days to Our Big Day!', message: 'Soon we\'ll celebrate with Mehndi and Sangeet!' },
  { days: 4, image: 'https://static.dhrumilpatel.in/images/RSg6n2ms.jpg', caption: '4 More Days!', message: 'The excitement grows as we prepare for our ceremonies!' },
  { days: 3, image: 'https://static.dhrumilpatel.in/images/RSl9c5bx.jpg', caption: '3 Days to Go!', message: 'Just three days until we take the sacred Pheras!' },
  { days: 2, image: 'https://static.dhrumilpatel.in/images/RSh4w8py.jpg', caption: '2 Days Until Forever!', message: 'Almost time for the Haldi ceremony and festivities!' },
  { days: 1, image: 'https://static.dhrumilpatel.in/images/RSd3f7qt.jpg', caption: 'Tomorrow is Our Wedding!', message: 'The final preparations for our grand celebration are underway!' }];

// Keeping existing gallery data
const galleryData = [
  {
    image: 'https://static.dhrumilpatel.in/images/wedding3.jpg',
    caption: 'Varmala',
    message: 'The beautiful garland exchange ceremony'
  },
  {
    image: 'https://static.dhrumilpatel.in/images/RSj7h5zt.jpg',
    caption: 'Wedding Ceremony',
    message: 'The sacred moment of our wedding ceremony'
  },
  {
    image: 'https://static.dhrumilpatel.in/images/RSq2c6lk.jpg', 
    caption: 'Reception',
    message: 'Celebrating our union with loved ones'
  }
];

const WeddingTimeline = ({ weddingDate }) => {
  const { isDarkMode } = useTheme();
  const [isMarried, setIsMarried] = useState(false);
  const [daysUntilWedding, setDaysUntilWedding] = useState(null);
  const [timelineEntries, setTimelineEntries] = useState([]);

  // Add this function to calculate if video should be visible
  const shouldShowVideo = () => {
    const now = new Date();
    const wedding = new Date(weddingDate);
    const fourHours = 4 * 60 * 60 * 1000;
    const diffTime = wedding - now - fourHours;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= 3 || isMarried;
  };

  // Keeping existing useEffect logic
  useEffect(() => {
    const calculateTimelineEntries = () => {
      const now = new Date();
      const wedding = new Date(weddingDate);
      const fourHours = 4 * 60 * 60 * 1000;
      const diffTime = wedding - now - fourHours;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      setDaysUntilWedding(diffDays);

      if (diffDays <= 0) {
        setIsMarried(true);
        setTimelineEntries([]);
        return;
      }

      const entries = timelineData.filter(entry => 
        entry.days === diffDays || entry.days === 14 || (entry.days <= 10 && entry.days >= diffDays)
      ).sort((a, b) => b.days - a.days);
      
      setTimelineEntries(entries);
      setIsMarried(false);
    };

    calculateTimelineEntries();
    const timer = setInterval(calculateTimelineEntries, 1000 * 60);
    return () => clearInterval(timer);
  }, [weddingDate]);

  // Anime-themed decorative elements
  const ThemeDecorations = () => (
    <div className="absolute inset-0 pointer-events-none overflow-hidden">
      {isDarkMode ? (
        // Itachi Theme Elements
        <>
          <motion.div
            className="absolute top-0 left-0 w-full h-32"
            initial={{ opacity: 0 }}
            animate={{ opacity: [0.1, 0.3, 0.1] }}
            transition={{ duration: 3, repeat: Infinity }}
          >
            <div className="absolute inset-0 bg-gradient-to-b from-red-900/20 to-transparent" />
          </motion.div>
          <motion.div
            className="absolute top-10 right-10"
            animate={{ rotate: 360 }}
            transition={{ duration: 20, repeat: Infinity, ease: "linear" }}
          >
            <div className="w-32 h-32 rounded-full border border-red-500/10" />
            <div className="absolute inset-0 rounded-full border border-red-500/10 transform rotate-45" />
          </motion.div>
        </>
      ) : (
        // One Piece Theme Elements
        <>
          <motion.div
            className="absolute bottom-0 left-0 w-full"
            animate={{ y: [-10, 0, -10] }}
            transition={{ duration: 4, repeat: Infinity }}
          >
            <div className="h-32 bg-gradient-to-t from-blue-500/20 to-transparent" />
          </motion.div>
          {Array.from({ length: 3 }).map((_, i) => (
            <motion.div
              key={`cloud-${i}`}
              className="absolute"
              style={{ top: `${20 + i * 30}%`, left: '-20%' }}
              animate={{ x: ['0%', '120%'] }}
              transition={{
                duration: 15 + i * 5,
                repeat: Infinity,
                ease: 'linear',
                delay: i * 2,
              }}
            >
              <div className="w-20 h-8 bg-white/10 rounded-full" />
            </motion.div>
          ))}
        </>
      )}
    </div>
  );

  const renderGallery = () => (
    <section id="gallery" className={`w-full py-8 sm:py-16 relative ${
      isDarkMode 
        ? 'bg-gradient-to-b from-gray-900 to-black' 
        : 'bg-gradient-to-b from-blue-800 to-blue-900'
    }`}>
      <ThemeDecorations />
      <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        <motion.h2 
          className={`text-3xl sm:text-4xl md:text-5xl font-tokyo text-center mb-8 sm:mb-16 ${
            isDarkMode ? 'text-red-400' : 'text-yellow-300'
          }`}
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          {isDarkMode ? "Eternal Memories" : "Our Adventure Album"}
        </motion.h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
          {galleryData.map((item, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className={`rounded-lg overflow-hidden shadow-xl ${
                isDarkMode ? 'bg-red-900/20' : 'bg-blue-900/20'
              }`}
            >
              <div className="relative group cursor-pointer aspect-[4/3]">
                <TimelineImage
                  src={item.image}
                  alt={item.caption}
                  className="absolute inset-0 w-full h-full object-cover"
                />
                <div className={`absolute inset-0 bg-gradient-to-t ${
                  isDarkMode ? 'from-red-900/60' : 'from-blue-900/60'
                } to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300`} />
                <motion.div
                  className="absolute bottom-4 left-4 right-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                >
                  <h3 className={`text-lg sm:text-xl font-tokyo ${
                    isDarkMode ? 'text-red-400' : 'text-yellow-300'
                  }`}>
                    {item.caption}
                  </h3>
                  <p className="text-white/80 text-sm mt-1 font-hachi">
                    {item.message}
                  </p>
                </motion.div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );

  const renderTimeline = () => (
    <section id="timeline" className={`w-full py-8 sm:py-16 relative ${
      isDarkMode 
        ? 'bg-gradient-to-b from-gray-900 to-black' 
        : 'bg-gradient-to-b from-blue-800 to-blue-900'
    }`}>
      <ThemeDecorations />
      <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        <motion.h2 
          className={`text-3xl sm:text-4xl md:text-5xl font-tokyo text-center mb-8 sm:mb-16 ${
            isDarkMode ? 'text-red-400' : 'text-yellow-300'
          }`}
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          {isDarkMode ? "Path to Eternal Union" : "The Greatest Adventure Begins"}
        </motion.h2>

        <div className="relative">
          {/* Timeline line with theme */}
          <div className={`absolute left-[20px] sm:left-[25px] top-0 bottom-0 w-1 ${
            isDarkMode 
              ? 'bg-gradient-to-b from-red-500/30 via-red-500/50 to-red-500/30' 
              : 'bg-gradient-to-b from-yellow-300/30 via-yellow-300/50 to-yellow-300/30'
          }`} />

          <div className="space-y-8 sm:space-y-16">
            {timelineEntries.map((entry, index) => (
              <motion.div
                key={entry.days}
                initial={{ opacity: 0, x: isDarkMode ? -50 : 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
                className="timeline-entry relative"
              >
                <div className="flex items-start space-x-3 sm:space-x-8">
                  {/* Themed day counter */}
                  <motion.div
                    className={`w-10 h-10 sm:w-[50px] sm:h-[50px] rounded-full flex items-center justify-center z-10 flex-shrink-0 ${
                      isDarkMode 
                        ? 'bg-gradient-to-br from-red-500 to-red-700'
                        : 'bg-gradient-to-br from-yellow-300 to-yellow-500'
                    }`}
                    whileHover={{ scale: 1.1, rotate: 360 }}
                    transition={{ duration: 0.3 }}
                  >
                    <span className="text-white font-metal text-base sm:text-xl">
                      {entry.days}
                    </span>
                  </motion.div>

                  <div className="flex-1 min-w-0">
                    <motion.div
                      className={`rounded-lg overflow-hidden shadow-xl ${
                        isDarkMode 
                          ? 'bg-gradient-to-br from-red-900/20 to-black/40 border-red-500/20' 
                          : 'bg-gradient-to-br from-blue-500/20 to-blue-900/40 border-yellow-300/20'
                      } border-2`}
                      whileHover={{ scale: 1.02 }}
                      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
                    >
                      <TimelineImage
                        src={entry.image}
                        alt={entry.caption}
                      />
                      <div className="p-4 sm:p-6">
                        <h3 className={`text-lg sm:text-xl md:text-2xl mb-2 ${
                          isDarkMode ? 'text-red-400' : 'text-yellow-300'
                        }`}>
                          {entry.caption}
                        </h3>
                        <p className="text-white/80 text-sm sm:text-base md:text-lg">
                          {entry.message}
                        </p>

                        {/* Theme-specific decorative icon */}
                        <motion.div
                          className="mt-4"
                          animate={{ rotate: [-5, 5, -5] }}
                          transition={{ duration: 3, repeat: Infinity }}
                        >
                          {isDarkMode ? (
                            <Flame className="w-6 h-6 text-red-500/40" />
                          ) : (
                            <Anchor className="w-6 h-6 text-yellow-300/40" />
                          )}
                        </motion.div>
                      </div>
                    </motion.div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );

  const renderVideoContent = () => {
    if (shouldShowVideo()) {
      return (
        <div className="relative aspect-video rounded-lg overflow-hidden shadow-2xl">
        <VideoPlayer 
          videoUrl="https://static.dhrumilpatel.in/videos/wedding.mov"
          posterUrl="https://static.dhrumilpatel.in/images/RSwy9k2m.jpg"
        />
      </div>
      );
    } else {
      return (
        <motion.div 
          className={`aspect-video rounded-lg overflow-hidden shadow-2xl flex flex-col items-center justify-center ${
            isDarkMode ? 'bg-red-900/20' : 'bg-blue-900/20'
          }`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <Lock className={`w-16 h-16 mb-4 ${
            isDarkMode ? 'text-red-400' : 'text-yellow-300'
          }`} />
          <h3 className={`text-xl sm:text-2xl font-tokyo mb-2 ${
            isDarkMode ? 'text-red-400' : 'text-yellow-300'
          }`}>
            Coming Soon
          </h3>
          <p className="text-white/80 text-center px-4">
            {daysUntilWedding > 3 && `Video will be available in ${daysUntilWedding - 3} days`}
          </p>
        </motion.div>
      );
    }
  };

  return (
    <div className="min-h-screen relative">
      <AnimeEffects isDarkMode={isDarkMode} />
      <SectionScroll />
      
      {/* Countdown Section */}
      <section 
        id="countdown" 
        className={`relative w-full min-h-screen flex items-center justify-center ${
          isDarkMode 
            ? 'bg-gradient-to-b from-black to-gray-900' 
            : 'bg-gradient-to-b from-blue-900 to-blue-800'
        }`}
      >
        <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <CountdownTimer targetDate={weddingDate} />
        </div>
      </section>
      
      {/* Gallery/Timeline Section */}
      <section 
        id="gallery" 
        className="relative w-full"
      >
        {isMarried ? renderGallery() : renderTimeline()}
      </section>
      
      {/* Video Highlights Section */}
      <section 
        id="highlights" 
        className={`w-full py-16 relative ${
          isDarkMode 
            ? 'bg-gradient-to-b from-black to-gray-900' 
            : 'bg-gradient-to-b from-blue-900 to-blue-800'
        }`}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.h2 
            className={`text-3xl sm:text-4xl md:text-5xl font-tokyo text-center mb-8 ${
              isDarkMode ? 'text-red-400' : 'text-yellow-300'
            }`}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            {isDarkMode ? "Eternal Moments" : "Adventure Highlights"}
          </motion.h2>

          {renderVideoContent()}

          <p className={`text-center mt-6 ${
            isDarkMode ? 'text-red-300/80' : 'text-yellow-200/80'
          }`}>
            {shouldShowVideo() 
              ? "Relive our special moments through this highlight reel"
              : "Our Pre-wedding highlights video will be available soon!"
            }
          </p>
        </div>
      </section>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default WeddingTimeline;